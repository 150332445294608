.FAQ-description {
  padding: 5.87vw 10.2vw 10.2vw 10.2vw;
  align-items: inherit; }

.FAQ-SubTitle, .FAQ-Content {
  text-align: left; }

.FAQ-SubTitle {
  margin-top: 3.06vw;
  margin-bottom: 4.59vw;
  font-size: 2.18vw;
  font-weight: bolder; }

.FAQ-Content {
  margin-bottom: 4.59vw;
  font-size: 2.18vw;
  line-height: 3.32vw; }

.FAQ-button-box {
  display: flex;
  margin-bottom: 4.08vw; }

.FAQ-button-box a:last-child {
  margin-left: 1.28vw; }
