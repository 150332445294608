.Copyright-container {
  display: flex;
  background: black;
  justify-content: center;
  align-items: center;
  height: 3.27vw;
  font-family: 'Avenir Heavy';
  font-size: 0.7vw; }

.Copyright-box {
  flex: 1;
  text-align: center;
  margin-left: 1.2vw; }

.Copyright-box:first-child {
  margin-left: 4.56vw;
  font-size: 1.12vw; }

.Copyright-box:last-child {
  margin-left: 0;
  font-size: 1.12vw; }
