.DeeplinkNotifyButton.Button {
  width: 18vw; }
  .DeeplinkNotifyButton.Button svg {
    margin-right: 10px; }
  .DeeplinkNotifyButton.Button .badge {
    position: relative;
    right: -0.5vw;
    top: -0.3vw; }

.DeeplinkNotifyButton.anim {
  animation: flash-anim 2s infinite; }

@keyframes flash-anim {
  0% {
    opacity: 0.3; }
  60% {
    opacity: 1; }
  100% {
    opacity: 0.3; } }

@keyframes angry-anim {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@media screen and (max-width: 1150px) {
  .DeeplinkNotifyButton.Button {
    width: 30vw;
    height: 8vw;
    font-size: 3.5vw;
    border-radius: 15px 0 0 15px; }
    .DeeplinkNotifyButton.Button svg {
      margin-right: 5px; }
    .DeeplinkNotifyButton.Button .badge {
      position: relative;
      right: -1vw;
      top: -0.3vw; }
    .DeeplinkNotifyButton.Button .btn-text {
      font-size: xx-small; } }
