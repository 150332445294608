.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 999;
  display: none; }

.show {
  display: flex;
  align-items: center;
  justify-content: center; }
