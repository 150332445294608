@font-face {
  font-family: 'Vintage College Dept';
  src: url('./fonts/VINTAGE_COLLEGE_DEPT_worn.otf') format("opentype");
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url('./fonts/AvenirLTStd-Book.otf') format("opentype");
}

@font-face {
  font-family: 'Avenir Light';
  src: url('./fonts/AvenirLTStd-Light.otf') format("opentype");
}

@font-face {
  font-family: 'Avenir Medium';
  src: url('./fonts/AvenirLTStd-Roman.otf') format("opentype");
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Vintage College Dept';
  color: white;
}

h1, h2, h3, h4, div, p {
	margin: 0px;
	padding: 0px;
  font-weight: normal;
}

h3, h4, a, button, header {
  font-family: 'Avenir Heavy';
}

p {
  font-family: 'Avenir Medium';
}

* {
	box-sizing: border-box;
}

button {
  border: none;
  color: white;
  cursor: pointer;
}
