.PoolGameView-container {
  font-size: 2.5vw; }
  .PoolGameView-container img {
    width: 100%; }
  .PoolGameView-container .PoolGameView-title-container {
    position: relative;
    text-align: center;
    margin-bottom: 15px; }
    .PoolGameView-container .PoolGameView-title-container .PoolGameView-date-box {
      font-size: 3.5vw; }
    .PoolGameView-container .PoolGameView-title-container .PoolGameView-pool-button {
      position: absolute;
      top: 0;
      font-size: 4.5vw;
      background: none; }
      .PoolGameView-container .PoolGameView-title-container .PoolGameView-pool-button.qrcode {
        left: 1.5vw; }
      .PoolGameView-container .PoolGameView-title-container .PoolGameView-pool-button.prize {
        right: 1.5vw;
        color: #ffe102; }
  .PoolGameView-container .PoolGameView-game-box {
    display: flex;
    justify-content: center;
    align-items: center; }
  .PoolGameView-container .PoolGameView-team-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 2 1 100%; }
    .PoolGameView-container .PoolGameView-team-box h4 {
      font-size: 3.5vw;
      font-weight: bold; }
  .PoolGameView-container .PoolGameView-team-vs-img {
    width: 35%; }
  .PoolGameView-container .PoolGameView-message-container {
    text-align: center; }
    .PoolGameView-container .PoolGameView-message-container .PoolGameView-pool-code {
      margin: -10px 0 10px 0; }
    .PoolGameView-container .PoolGameView-message-container .PoolGameView-pool-status.red {
      color: red; }
  .PoolGameView-container .PoolGameView-button-box {
    display: none;
    text-align: center;
    margin-top: 10px; }
    .PoolGameView-container .PoolGameView-button-box a {
      display: block;
      margin: 0 auto;
      color: white;
      text-decoration: none;
      background: #292929;
      width: 15vw;
      height: 15vw;
      font-size: 3vw;
      line-height: 6.5vw;
      border-radius: 10vw;
      border: 2px solid white; }
  .PoolGameView-container .flash {
    animation-name: flash;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }

@media screen and (max-width: 1150px) {
  .PoolGameView-container {
    font-size: 4vw; }
    .PoolGameView-container .PoolGameView-title-container .PoolGameView-date-box {
      font-size: 5.5vw; }
    .PoolGameView-container .PoolGameView-title-container .PoolGameView-pool-prize {
      font-size: 8.5vw; }
    .PoolGameView-container .PoolGameView-title-container .PoolGameView-pool-button {
      font-size: 6vw; }
    .PoolGameView-container .PoolGameView-team-vs-img {
      width: 30%; }
    .PoolGameView-container .PoolGameView-team-box h4 {
      font-size: 5.5vw; }
    .PoolGameView-container .PoolGameView-button-box {
      display: block; } }

@keyframes flash {
  0% {
    opacity: 1.0; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1.0; } }

.PoolPrizeView-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .PoolPrizeView-container .PoolPrizeView-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    .PoolPrizeView-container .PoolPrizeView-box:first-child {
      margin-bottom: 1vw; }
    .PoolPrizeView-container .PoolPrizeView-box .PoolPrizeView-box-title {
      font-size: 3vw;
      font-weight: bold;
      color: yellow;
      padding: 10px;
      text-align: center; }
    .PoolPrizeView-container .PoolPrizeView-box .PoolPrizeView-box-body {
      padding: 5px 20px;
      font-size: 2vw;
      word-break: break-all; }
      .PoolPrizeView-container .PoolPrizeView-box .PoolPrizeView-box-body .PoolPrizeView-list {
        list-style-type: none;
        padding: 0;
        margin: 0; }
        .PoolPrizeView-container .PoolPrizeView-box .PoolPrizeView-box-body .PoolPrizeView-list li {
          text-indent: -1em; }
  .PoolPrizeView-container .PoolPrizeView-back-button {
    position: absolute;
    top: 0;
    left: 1vw;
    background: none;
    font-size: 4.5vw; }

@media screen and (max-width: 1150px) {
  .PoolPrizeView-container .PoolPrizeView-box .PoolPrizeView-box-title {
    font-size: 6vw; }
  .PoolPrizeView-container .PoolPrizeView-box .PoolPrizeView-box-body {
    padding: 5px 20px;
    font-size: 4vw; }
  .PoolPrizeView-container .PoolPrizeView-back-button {
    font-size: 6vw; } }

.PoolQRCodeView-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .PoolQRCodeView-container .PoolQRCodeView-qrcode-box .PoolQRCodeView-qrcode-box-title {
    font-size: 3vw;
    font-weight: bold;
    color: yellow;
    padding: 10px;
    text-align: center; }
  .PoolQRCodeView-container .PoolQRCodeView-qrcode-box .PoolQRCodeView-qrcode-box-content {
    margin: 15px;
    text-align: center; }
  .PoolQRCodeView-container .PoolQRCodeView-back-button {
    position: absolute;
    top: 0;
    left: 0;
    background: none;
    font-size: 4.5vw; }

@media screen and (max-width: 1150px) {
  .PoolQRCodeView-container .PoolQRCodeView-qrcode-box .PoolQRCodeView-qrcode-box-title {
    font-size: 6vw; }
  .PoolQRCodeView-container .PoolQRCodeView-back-button {
    font-size: 6vw; } }
