.Player-Banner-Content-1 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .Player-Banner-Content-1 h1 {
    font-size: 5.45vw;
    margin-top: 1.28vw; }
  .Player-Banner-Content-1 p {
    font-size: 3vw; }

.Player-download-button {
  width: 23.83vw;
  height: 4.59vw;
  margin-top: 2.35vw; }

@media screen and (max-width: 1150px) {
  .Player-download-button {
    width: 30.13vw;
    height: 6.4vw; } }
