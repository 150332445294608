.Button {
  background: #027502;
  width: 15vw;
  height: 3vw;
  font-size: 1.5vw;
  line-height: 3vw;
  border-radius: 5px;
  box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.2); }

.Button.play-button {
  margin-inline: 10px; }

@media screen and (max-width: 1150px) {
  .Button.play-button {
    width: auto !important;
    height: auto; } }
