.Media-container {
  position: absolute;
  top: 15%;
  right: 6.6%;
  width: 19.6%; }

#play-demo-video {
  cursor: pointer; }

#play-demo-video::-webkit-media-controls-start-playback-button {
  display: none;
  -webkit-appearance: none; }

@media screen and (max-width: 1150px) {
  .Media-container {
    top: 17%;
    right: 11.5%;
    width: 31%; } }
