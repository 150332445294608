.DeeplinkGameButton.Button {
  position: absolute;
  right: 0;
  background-color: #da0101;
  margin-top: 5px; }
  .DeeplinkGameButton.Button svg {
    margin-right: 10px; }

@media screen and (max-width: 1150px) {
  .DeeplinkGameButton.Button {
    width: 30vw;
    height: 8vw;
    font-size: 3.5vw;
    border-radius: 15px 0 0 15px; }
    .DeeplinkGameButton.Button .btn-text {
      font-size: 12px; } }
