.LinkButton {
  display: block;
  background: black;
  background-size: 100% 100%;
  width: 15.10vw;
  height: 5.83vw;
  border-radius: 0.5vw;
  box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.2);
  color: white;
  text-align: center;
  text-decoration: none; }

.LinkButton.apple-store {
  background-image: url(./app-store.png); }

.LinkButton.google-play {
  background-image: url(./google-play.png); }

.LinkButton.facebook {
  background-image: url(./facebook.png); }

.LinkButton.instagram {
  background-image: url(./instagram.png); }

.LinkButton.linkedin {
  background-image: url(./linkedin.png); }

.LinkButton.twitter {
  background-image: url(./twitter.png); }
