.Footer-container {
  display: flex;
  background: #1d1d1d; }

.Footer-box {
  flex: 1 1 auto;
  margin: 0 3.93vw 4.34vw 3.93vw; }

.Footer-box:first-child {
  margin-left: 9.08vw; }

.Footer-box:last-child {
  margin-right: 9.08vw; }

.Footer-logo {
  margin: 3.11vw 0 2.33vw 0; }
  .Footer-logo img {
    height: 5.46vw; }

.Footer-subtitle {
  margin: 5.87vw 0 4.08vw 0; }

.Footer-content {
  font-size: 0.95vw;
  line-height: 1.53vw;
  text-align: left;
  height: 7.77vw; }

.Footer-download-button-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.92vw; }

.Footer-download-button-box a {
  width: 10.2vw;
  height: 3.16vw; }

.Footer-download-button-box a:last-child {
  margin-left: 0.51vw; }

.Footer-Navbar {
  list-style: none;
  padding: 0px;
  display: flex;
  align-items: center; }

.Footer-Navbar li {
  display: inline-block;
  margin: 0px auto; }

.Footer-Navbar li a {
  color: white;
  text-decoration: none; }

.Footer-Navbar li a:hover {
  text-decoration: underline; }

@media screen and (max-width: 1150px) {
  .Footer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Avenir Medium'; }
  .Footer-box {
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.91vw; }
    .Footer-box .Footer-followus-text {
      margin-right: 2.27vw; }
    .Footer-box:first-child {
      margin-left: 0vw; }
    .Footer-box:last-child {
      margin-right: 0vw; }
  .Footer-box:first-child {
    margin-top: 50px; } }
