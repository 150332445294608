.SocialMedia-container {
  display: flex;
  justify-content: center;
  align-items: center; }

.SocialMedia-button {
  width: 3.47vw;
  height: 3.47vw;
  margin: 0 0.82vw;
  border-radius: 0; }

@media screen and (max-width: 1150px) {
  .SocialMedia-button {
    width: 8vw;
    height: 8vw;
    margin: 0 2.67vw; } }
