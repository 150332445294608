.Host-Banner-Content-1 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.Host-Banner-Content-2 {
  top: 12.5vw;
  left: 52.5vw; }
  .Host-Banner-Content-2 h1 {
    margin-bottom: 1.1vw; }
  .Host-Banner-Content-2 p {
    font-size: 1.77vw; }

.Host-download-button {
  width: 23.83vw;
  height: 4.59vw;
  margin-top: 2.35vw; }

@media screen and (max-width: 1150px) {
  .Host-download-button {
    width: 30.13vw;
    height: 6.4vw; } }
