.CopyrightNavbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Avenir Heavy';
  font-size: 0.8vw; }

.CopyrightNavbar-menu {
  list-style: none;
  padding: 0px;
  display: flex;
  align-items: center; }

.CopyrightNavbar-menu li {
  display: inline-block;
  margin: 0px auto;
  padding: 0px 1.35vw; }

.CopyrightNavbar-menu li a {
  color: white;
  text-decoration: none; }

.CopyrightNavbar-menu li a:hover {
  text-decoration: underline; }

@media screen and (max-width: 1150px) {
  .CopyrightNavbar-container {
    font-size: 3.91vw; }
  .CopyrightNavbar-menu li {
    display: inline-block;
    margin: 0px auto;
    padding: 0px 2.67vw; } }
