.HowToBox-box {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center; }

.HowToBox-img-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .HowToBox-img-box .HowToBox-img {
    margin-left: 5.2vw;
    width: 25vw;
    height: 25vw; }

.HowToBox-img-arrow {
  margin: -0.51vw 0 -0.51vw 5vw; }

.HowToBox-title-box {
  flex: 2;
  margin-top: -6.12vw; }

.HowToBox-title-box-noArrow {
  flex: 2;
  margin-top: 0px; }

.HowToBox-subtitle {
  font-size: 3.4vw;
  font-weight: bolder;
  margin-bottom: 0.51vw; }

.HowToBox-content {
  font-size: 2.31vw; }

@media screen and (max-width: 1150px) {
  .HowToBox-img-box .HowToBox-img {
    margin-left: 8.93vw;
    width: 29.33vw;
    height: 29.33vw; }
  .HowToBox-img-arrow {
    height: 11.2vw;
    margin: -0.51vw 0 -1.7vw 8.7vw; } }
