.Home-Banner-Content-1 {
  top: 16vw;
  left: 3vw; }

.Home-Banner-Content-1 .SubTitle {
  font-size: 2.4vw; }

.Home-Banner-Content-1 p {
  font-weight: bold; }

.Home-Banner-Content-2 {
  top: 15.1vw;
  right: 8.08vw; }

.Home-Banner-Content-2 p {
  font-weight: bold; }

.Home-button-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.04vw auto; }

.Home-button-box a {
  margin: auto 1.02vw; }

.Home-download-button {
  width: 23.82vw;
  height: 4.59vw;
  margin: 3.72vw auto; }

.getstart-description {
  height: 61.22vw;
  position: relative; }

.getstart-container {
  display: flex;
  width: 100%; }

.getstart-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center; }

.getstart-img-box {
  margin-bottom: 2.8vw; }
  .getstart-img-box img {
    width: 13.52vw;
    height: 13.52vw; }

.getstart-title {
  margin: 0vw 0 5.1vw 0; }

.getstart-subtitle {
  font-size: 2.38vw;
  font-weight: bold;
  margin-bottom: 0.51vw; }

.getstart-button {
  width: 24.29vw;
  height: 4.59vw;
  background: #122A88;
  font-size: 1.7vw;
  line-height: 4.7vw;
  margin: 3vw 0 3vw 0; }

@media screen and (max-width: 1150px) {
  .Home-Banner-Content-1 {
    top: 6.5vw;
    left: 3vw; }
  .Home-Banner-Content-1 p {
    margin-top: 2.2vw; }
  .Home-Banner-Content-1 .SubTitle {
    font-size: 4vw; }
  .Home-button-box {
    flex-direction: column;
    margin: 2.04vw auto; }
  .Home-button-box a {
    width: 19.73vw;
    height: 6.93vw;
    margin: 0.73vw auto; }
  .getstart-description {
    height: 100%; }
  .getstart-container {
    display: flex;
    flex-direction: column;
    width: 100%; }
  .getstart-box {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 7.2vw 7vw 3vw 0; }
  .getstart-img-box {
    margin: 0;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4vw; }
    .getstart-img-box img {
      width: 29.33vw;
      height: 29.33vw; }
  .getstart-title-box {
    flex: 1; }
    .getstart-title-box .getstart-subtitle {
      font-size: 5.33vw;
      margin-bottom: 2.5vw; }
    .getstart-title-box .getstart-content {
      font-size: 3.55vw; }
  .getstart-button {
    margin: 9.87vw 0;
    width: 43.47vw;
    height: 9.33vw;
    line-height: 9.33vw;
    font-size: 4vw; }
  .Home-Banner-Content-2 {
    top: 12.5vw;
    right: 0;
    left: 50vw; }
  .Home-Banner-Content-2 p {
    margin-top: 2vw; }
  .Home-download-button {
    width: 43.73vw;
    height: 8.53vw;
    margin: 3.72vw auto; } }
