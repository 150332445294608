.DeeplinkButton-container {
  position: fixed;
  top: 7vw;
  left: initial;
  right: 0vw;
  z-index: 999;
  white-space: nowrap; }

@media screen and (max-width: 1150px) {
  .DeeplinkButton-container {
    position: fixed;
    top: 11vw;
    left: initial;
    right: 0vw;
    z-index: 999; } }
