.ContactBox-container {
  display: flex;
  flex-direction: column; }

.ContactBox-box {
  display: flex;
  align-items: center;
  margin: 0.51vw 0; }
  .ContactBox-box a {
    color: #00edff; }
  .ContactBox-box img {
    margin-top: -0.5vw;
    margin-right: 0.77vw; }

@media screen and (max-width: 1150px) {
  .ContactBox-container {
    margin-bottom: 5.67vw; }
  .ContactBox-box {
    margin: 2.13vw 0; }
    .ContactBox-box img {
      margin-top: -1vw;
      margin-right: 2.4vw; } }
