.HowTo-container {
  width: 100%;
  margin-top: 1.43vw; }

.HowTo-title {
  margin: 0 0 0 0; }

.HowTo-button {
  width: 23.83vw;
  height: 4.59vw;
  background: #122A88;
  font-size: 1.7vw;
  line-height: 4.59vw;
  margin: 6.12vw 0; }

@media screen and (max-width: 1150px) {
  .HowTo-button {
    margin: 9.87vw 0;
    width: 43.47vw;
    height: 9.33vw;
    line-height: 9.33vw;
    font-size: 4vw; } }
