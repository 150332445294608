@keyframes marquee {
  0% {
    margin-left: 120%; }
  100% {
    margin-left: -200%; } }

.marquee {
  animation: marquee 15s linear 0s infinite; }

.NotificationBar-container {
  height: 2.54vw;
  width: 100%;
  background: #ff0000;
  display: flex;
  justify-content: center;
  align-items: center; }

.NotificationBar-container.fixed {
  position: fixed;
  bottom: 0;
  z-index: 999; }

.NotificationBar-container header {
  font-size: 1.36vw;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap; }
  .NotificationBar-container header:hover {
    animation-play-state: paused; }

@media screen and (max-width: 1150px) {
  .NotificationBar-container {
    height: 10vw; }
    .NotificationBar-container header {
      font-size: 4vw; } }
