.Navbar-container {
  background: black;
  height: 6.38vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Avenir Heavy';
  font-size: 1.22vw; }

.Navbar-logo {
  height: 4.5vw;
  margin-left: 3vw; }

.Navbar-menu {
  list-style: none;
  padding: 0px;
  display: flex;
  align-items: center; }

.Navbar-menu li {
  display: inline-block;
  margin: 0px auto;
  padding: 0px 2.73vw; }

.Navbar-menu li a {
  color: white;
  text-decoration: none; }

.Navbar-menu li a:hover {
  text-decoration: underline; }

.Navbar-button {
  width: 15vw; }

@media screen and (max-width: 1150px) {
  .Navbar-container {
    font-size: 3.55vw;
    height: 9.95vw; }
  .Navbar-logo {
    height: 6.5vw;
    margin-left: 3vw; }
  .Navbar-menu li {
    padding: 0px 4.67vw; }
  .Navbar-button {
    width: 30.13vw; } }
